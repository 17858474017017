<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive = true">
      <i
        v-if="params.data.reminder_type_isactive == 4"
        class="text-center material-icons"
        >unarchive</i
      >
      <i v-else class="text-center material-icons">archive</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA' + params.data.reminder_type_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Reminder Type Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5 v-if="params.data.reminder_type_isactive == '4'">
            Do you want to unarchive?
          </h5>
          <h5 v-else-if="params.data.reminder_type_isactive == '1' || '2'">
            Do you want to archive?
          </h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >
              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import ContractService from "@/services/ContractService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        reminder_type_isactive: "4"
      },
      copy_reminder_type_isactive: null
    };
  },
  beforeMount() {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalA" + this.params.data.reminder_type_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      console.log("this.params", this.params);
      this.$vs.loading();
      let id = this.params.data.reminder_type_id;

      let payload = {
        reminder_type_name: this.params.data.reminder_type_name,
        reminder_type_day: this.params.data.reminder_type_day,
        reminder_type_isactive:
          this.params.data.reminder_type_isactive == "4" ? "1" : "4"
      };
      ContractService.editContractVendor(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if ( data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            setTimeout(() => {
              eventBus.$emit("conrefreshTableReminderType", data);
            }, 1);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.reminder_type_isactive = this.copy_reminder_type_isactive;
    }
  }
});
</script>
